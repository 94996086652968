<template>
  <div
    class="promotion"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <Breadcrumb />

    <div class="promotion__header">
      <h1
        itemprop="name"
        class="promotion__title"
      >
        <Lang
          by-key="special-moments"
          capitalize-all
        />
      </h1>

      <p class="promotion__description">
        <Lang by-key="promotion-home-desc" />
      </p>
    </div>

    <!-- Service list -->
    <div class="promotion__list-wrapper">
      <!-- filtros -->
      <div>
        <div class="only-desktop-block">
          <Card
            desktop-with-border
            class="promotion__filters"
          >
            <div class="promotion__filters__title">
              <Lang
                by-key="filter-by"
                capitalize-first
              />
            </div>

            <div class="promotion__filters__expanded-filter">
              <p class="promotion__filters__expanded-filter__header">
                <Lang
                  by-key="promotion"
                  capitalize-all
                />
              </p>

              <SplNuxtLink
                v-for="promo in promotions"
                :key="'promo' + promo.uuid"
                :to="makePromotionURL(promo)"
                is-external
              >
                <p
                  class="promotion__filters__expanded-filter__link"
                >
                  {{ promo.title }}
                </p>
              </SplNuxtLink>
            </div>
          </Card>
        </div>
        <div class="only-mobile">
          <div class="promotion__mobile-filters">
            <StickyHeader>
              <template #only-sticky>
                <h1 class="promotion__mobile-filters__header__title">
                  <Lang
                    by-key="promotion"
                    capitalize-all
                    plural
                  />
                </h1>
              </template>
              <template #body>
                <div class="promotion__mobile-filters__header">
                  <div class="promotion__mobile-filters__header__controls">
                    <button
                      class="promotion__mobile-filters__button"
                      @click="setIsModalDisplayed(true)"
                    >
                      <HopperIcon
                        class="promotion__mobile-filters__button__icon"
                        :size="18"
                      />
                      <span><Lang
                        by-key="filter"
                        capitalize-first
                      /></span>
                    </button>

                    <SelectBox
                      id="promotion-sorter"
                      variant="minified"
                      :data="sortOptions"
                      display-prop="label"
                      class="promotion__mobile-filters__sort-selector"
                      @input="pushSortOrder"
                    >
                      <template #label>
                        <Lang
                          by-key="sort-by"
                          capitalize-first
                        />: <span class="promotion__mobile-filters__sort-selector__label">{{
                          selectedSortOptionLabel }}</span>
                      </template>
                    </SelectBox>
                  </div>
                </div>
              </template>
            </StickyHeader>

            <ConfigModalMobile
              v-model="isModalDisplayed"
            >
              <template #header>
                <div class="promotion__mobile-filters__header">
                  <Lang
                    by-key="filter-by"
                    capitalize-first
                  />
                </div>
              </template>

              <div
                class="promotion__mobile-filters__expanded-filter"
              >
                <div class="promotion__mobile-filters__expanded-filter">
                  <p class="promotion__mobile-filters__expanded-filter__header">
                    <Lang
                      by-key="promotion"
                      capitalize-all
                    />
                  </p>

                  <SplNuxtLink
                    v-for="promo in promotions"
                    :key="'promo' + promo.uuid"
                    :to="makePromotionURL(promo)"
                    is-external
                  >
                    <p
                      class="promotion__mobile-filters__expanded-filter__link"
                    >
                      {{ promo.title }}
                    </p>
                  </SplNuxtLink>
                </div>
              </div>
            </ConfigModalMobile>
          </div>
        </div>
      </div>
      <div class="promotion__service-list">
        <div class="promotion__service-list__header">
          <div>
            <h2 class="promotion__service-list__header__subtitle">
              {{ subtitle }}
            </h2>
          </div>

          <div>
            <SelectBox
              id="promotion-sorter"
              variant="minified"
              :data="sortOptions"
              display-prop="label"
              class="promotion__service-list__header__sort-selector"
              @input="pushSortOrder"
            >
              <template #label>
                <Lang
                  by-key="sort-by"
                  capitalize-first
                />: <span class="promotion__service-list__header__sort-selector__label">{{
                  selectedSortOptionLabel }}</span>
              </template>
            </SelectBox>
          </div>
        </div>
        <!-- Lista en sí de servicios -->
        <div>
          <TruncatedList
            :split-at="24"
            :data="servicesView"
            desktop-table-col-2
          >
            <template #default="{ element }">
              <ServiceListItem
                :service="element"
                :spa="getSpaByUuid(element.spaUUID)"
                show-spa-details
                :selected-pax="selectedPax"
                :has-selected-pax="hasSelectedPax"
              />
            </template>
          </TruncatedList>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import Card from '~/core/components/shared/Card.vue'
import TruncatedList from '~/core/components/shared/TruncatedList.vue'
import ServiceListItem from '~/core/components/spa-service/atom/ServiceListItem.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import StickyHeader from '~/core/components/shared/StickyHeader.vue'
import ConfigModalMobile from '~/core/components/shared/form/ConfigModalMobile.vue'
import HopperIcon from '~/core/components/shared/icons/Hopper.icon.vue'

import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { useSorter } from '~/core/composable/spa/useSorter'
import { capitalizeAllWords, capitalizeFirstWord, joinPaths } from '~/core/ts/util/string'
import { translate, translateByKey } from '~/core/ts/util/translate'

import { ServiceCollection, type Service } from '~/core/ts/entity/Service'
import type { Spa } from '~/core/ts/entity/Spa'
import { Promotion } from '~/core/ts/entity/Promotion'
import { getPromotionMetadata } from '~/core/composable/promotion/usePromotionPageHeader'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import type { PromotionLanding } from '~/core/ts/entity/PromotionLanding'


export default defineNuxtComponent({
  components: {
    Breadcrumb,
    Card,
    TruncatedList,
    ServiceListItem,
    SelectBox,
    SplNuxtLink,
    StickyHeader,
    ConfigModalMobile,
    HopperIcon,
  },
  async setup() {
    const route = useRoute()
    const runtimeConfig = useRuntimeConfig()

    const {
      selectedPax,
      hasSelectedPax,
    } = usePaxSelector(ref([]), '0')

    const recommendedText = capitalizeAllWords(translateByKey('recommended', true))
    const cheapestServiceText = capitalizeAllWords(translateByKey('cheapest-service'))
    const mostExpensiveServiceText = capitalizeAllWords(translateByKey('most-expensive-service'))

    const {
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel
    } = useSorter([
      { label: cheapestServiceText, value: 'cheapest' },
      { label: mostExpensiveServiceText, value: 'more_expensive' },
      { label: recommendedText, value: 'recommended' },
    ], 'cheapest')

    const isModalDisplayed = ref(false)
    function setIsModalDisplayed(value: boolean) {
      isModalDisplayed.value = value
    }

    // Get data -------
    const uri = route.path
    const promotionLanding = await useFetchProxy<PromotionLanding>(
      '/api/promotions/promotion-landing-by-uri',
      { method: 'post', body: { uri } })

    const services = await useFetchProxy<Service[]>(
      '/api/services/services-by-uuid-list', { method: 'post', body: { uuids: promotionLanding.payload } })

    // Lectura de Spas
    const spaUUIDsDict: Record<string, string> = {}
    services.forEach((service) => spaUUIDsDict[service.spaUUID] = service.spaUUID)
    const spaUUIDs = Object.keys(spaUUIDsDict)

    const spas = await useFetchProxy<Spa[]>(
      '/api/spas/spa-list-by-uuid-list-no-services', { method: 'post', body: { uuids: spaUUIDs } })

    const spasByUuid: Record<string, Spa> = {}
    spas.forEach((spa) => spasByUuid[spa.uuid] = spa)

    const promotionsResponse = await useFetch<Array<Promotion>>(
      '/api/promotions/promotion-list-with-landing', {
        key: 'promotion-home-promotions'
      })
    const promotions = (promotionsResponse.data.value as Array<Promotion>)

    // End Get data -------

    function makePromotionURL(promo: Promotion) {
      const trans = translate(promo.texts)

      return joinPaths(runtimeConfig.public.urls.promotionBaseUrl, trans.slug)
    }

    function getSpaByUuid(uuid: string): Spa {
      return spasByUuid[uuid]
    }

    const servicesView = computed(() => {
      const view: Array<Service> = services.slice()
      const collection = new ServiceCollection(view)

      switch (sortOrder.value.value) {
        case 'cheapest':
          collection.sortByCheaper(new Date())
          break
        case 'more_expensive':
          collection.sortByMostExpensive(new Date())
          break
        case 'recommended':
          collection.sortByRecommended()
          break
      }

      return collection.services
    })

    const firstServiceImage = (() => {
      if (services.length === 0) {
        return ''
      }

      if (services[0].images.length === 0 ) {
        return ''
      }

      return services[0].images[0].url
    })()
    useHead(getPromotionMetadata(route.path, null, null, firstServiceImage))

    const foundTexts = capitalizeFirstWord(translateByKey('found', false))
    const foundTextp = capitalizeFirstWord(translateByKey('found', true))
    const subtitle = computed(() => {
      if (servicesView.value.length === 1) {
        return `${foundTexts} 1 Servicio`
      }

      return `${foundTextp} ${servicesView.value.length} Servicios`
    })

    return {
      subtitle,

      // Datos de servicios y spas
      promotions,
      getSpaByUuid,
      servicesView,

      makePromotionURL,

      // Modal
      isModalDisplayed,
      setIsModalDisplayed,

      // Pax selection
      selectedPax,
      hasSelectedPax,

      // Ordenador
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel,
    }
  }
})
</script>

<style lang="scss" scoped>
.promotion {
  @apply py-1;

  &__header {
    @apply px-2;
    @apply pb-2;

    @screen lg {
      @apply px-0;
    }
  }

  &__title {
    @apply mt-20p mb-20p;
    @apply text-xl;
    @apply text-spl-dark;
    @apply font-semibold;

    @apply px-2;

    @screen lg {
      @apply text-3xl;
      @apply px-0;
    }
  }

  &__description {
    @apply px-2;

    @screen lg {
      @apply px-0;
    }
  }

  &__filters {
    @apply py-4;
    @apply px-4;

    &__title {
      @apply text-lg;
      @apply font-bold;
      @apply text-spl-dark;

      @apply w-full;
      @apply py-1;
      @apply my-1;
      @apply border-b-1;
      @apply border-spl-secondary;
    }

    &__expanded-filter {
      @apply py-2;

      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__link {
        @apply pl-2;
        @apply py-1;
      }

      &__link:hover {
        @apply pl-4;
      }
    }
  }

  &__mobile-filters {
    &__header {
      &__title {
        @apply text-xl;
        @apply text-spl-dark;
        @apply py-4;
        @apply px-2;
        @apply font-semibold;
      }

      &__controls {
        @apply py-2;
        @apply flex justify-end;
      }
    }

    &__button {
      @apply flex items-center;
      @apply text-sm;

      @apply px-4;
      @apply py-2;
      @apply border-r-1;

      &__icon {
        @apply mr-1;
      }
    }

    &__expanded-filter {
      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      @apply pb-2 mb-2;

      &__link {
        @apply pl-2;
        @apply py-1;

        @apply underline;
      }

      &__link:hover {
        @apply pl-4;
      }
    }

    &__sort-selector {
      @apply text-sm;
      @apply px-4;
      @apply py-2;

      &__label {
        @apply font-bold;
        @apply italic;
      }
    }
  }

  &__list-wrapper {
    @apply mt-2;
    @apply pt-4;

    @screen lg {
      @apply gap-3;
      @apply grid;
      grid-template-columns: 25% 75%;
    }
  }

  &__service-list {
    &__header {
      @apply hidden;

      @screen lg {
        @apply flex;
        @apply justify-between;
        @apply items-center;
      }

      @apply px-2 pb-1;

      &__subtitle {
        @apply text-spl-dark;
        @apply text-sm;
      }

      &__sort-selector {
        @apply my-2;

        &__label {
          @apply font-bold;
          @apply italic;
        }
      }
    }
  }
}

</style>
