<template>
  <div
    class="promotion"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <Breadcrumb />

    <div class="promotion__header">
      <div
        v-if="score"
        property="aggregateRating"
        itemprop="aggregateRating"
        itemscope
        itemtype="https://schema.org/AggregateRating"
        class="promotion__header__score"
      >
        <span
          class="util-snippet"
          itemprop="ratingValue"
        >{{ score }}</span>
        <span
          class="util-snippet"
          itemprop="bestRating"
        >{{ maxScore }}</span>
        <span
          class="util-snippet"
          itemprop="reviewCount"
        >{{ reviewCount }}</span>
      </div>

      <PromotionSmallDescription
        :promotion="promotion"
        :location="location"
      >
        <template #title>
          <h1
            itemprop="name"
            class="promotion__title"
          >
            {{ title }}
          </h1>
        </template>
      </PromotionSmallDescription>
    </div>

    <!-- Service list -->
    <div class="promotion__list-wrapper">
      <!-- filtros -->
      <div>
        <div class="only-desktop-block">
          <Card
            desktop-with-border
            class="promotion__filters"
          >
            <div class="promotion__filters__title">
              <Lang
                by-key="filter-by"
                capitalize-first
              />
            </div>

            <div
              v-if="filterLocationsTree.length"
              class="promotion__filters__expanded-filter"
            >
              <p class="promotion__filters__expanded-filter__header">
                <Lang
                  by-key="region"
                  capitalize-first
                />
              </p>

              <LocationLinkTree
                :countries="countries"
                :link-url-base="locationFilterBaseUrl"
                :general-parent-location="generalParentLocation"
                :locations-tree="filterLocationsTree"
                :current-location="location"
                :link-query="paxLinkQueryParam"
              />
            </div>
            <hr>

            <div
              v-if="promoCategoryNodes.length"
              class="promotion__mobile-filters__expanded-filter"
            >
              <p class="promotion__mobile-filters__expanded-filter__header">
                <Lang
                  by-key="promotion"
                  capitalize-first
                  plural
                />
              </p>

              <TruncatedList
                :split-at="7"
                :data="promoCategoryNodes"
                expand-button-left
              >
                <template #default="{ element }">
                  <div
                    class="promotion__filters__expanded-filter__checkbox-node"
                    :class="{'selected': element.value}"
                  >
                    <input
                      :id="`category-checkbox-${element.id}`"
                      v-model="element.value"
                      type="checkbox"
                      @change="onPromoCategoryNodeClick"
                    >
                    <label :for="`category-checkbox-${element.id}`">
                      <Lang
                        :by-raw="element.texts"
                      />
                    </label>
                  </div>
                </template>
              </TruncatedList>
            </div>

            <hr>

            <div
              v-if="treatmentCategoryNodes.length"
              class="promotion__mobile-filters__expanded-filter"
            >
              <p class="promotion__mobile-filters__expanded-filter__header">
                <Lang
                  by-key="treatment"
                  capitalize-first
                  plural
                />
              </p>

              <TruncatedList
                :split-at="7"
                :data="treatmentCategoryNodes"
                expand-button-left
              >
                <template #default="{ element }">
                  <div
                    class="promotion__filters__expanded-filter__checkbox-node"
                    :class="{'selected': element.value}"
                  >
                    <input
                      :id="`category-checkbox-${element.id}`"
                      v-model="element.value"
                      type="checkbox"
                      @change="onTreatmentCategoryNodeClick"
                    >
                    <label :for="`category-checkbox-${element.id}`">
                      <Lang
                        :by-raw="element.texts"
                      />
                    </label>
                  </div>
                </template>
              </TruncatedList>
            </div>

            <hr>

            <div class="promotion__filters__expanded-filter">
              <p class="promotion__filters__expanded-filter__header">
                <Lang
                  by-key="person"
                  capitalize-first
                  plural
                />
              </p>

              <PaxInlineSelector />
            </div>
          </Card>
        </div>
        <div class="only-mobile">
          <div class="promotion__mobile-filters">
            <StickyHeader>
              <template #only-sticky>
                <div class="promotion__mobile-filters__header">
                  <h1 class="promotion__mobile-filters__header__title">
                    {{ title }}
                  </h1>

                  <div class="promotion__mobile-filters__header__location-selector">
                    <SessionLocationSelector dark />
                  </div>
                </div>
              </template>
              <template #body>
                <div class="promotion__mobile-filters__header">
                  <div class="promotion__mobile-filters__header__controls">
                    <button
                      class="promotion__mobile-filters__button"
                      @click="setIsModalDisplayed(true)"
                    >
                      <HopperIcon
                        class="promotion__mobile-filters__button__icon"
                        :size="18"
                      />
                      <span><Lang
                        by-key="filter"
                        capitalize-first
                      /></span>
                    </button>

                    <SelectBox
                      id="promotion-sorter"
                      variant="minified"
                      :data="sortOptions"
                      display-prop="label"
                      class="promotion__mobile-filters__sort-selector"
                      @input="pushSortOrder"
                    >
                      <template #label>
                        <Lang
                          by-key="sort-by"
                          capitalize-first
                        />: <span class="promotion__mobile-filters__sort-selector__label">{{
                          selectedSortOptionLabel }}</span>
                      </template>
                    </SelectBox>
                  </div>

                  <div class="promotion__mobile-selected-filters">
                    <div
                      v-if="selectedPromos.length"
                      class="promotion__mobile-selected-filters__row"
                    >
                      <Badge
                        v-for="category in selectedPromos"
                        :key="category.id"
                        class="promotion__mobile-selected-filters__category"
                      >
                        {{ category.label }}
                      </Badge>
                    </div>

                    <div
                      v-if="selectedTreatments.length"
                      class="promotion__mobile-selected-filters__row"
                    >
                      <Badge
                        v-for="category in selectedTreatments"
                        :key="category.id"
                        color="secondary"
                        class="promotion__mobile-selected-filters__category"
                      >
                        {{ category.label }}
                      </Badge>
                    </div>
                  </div>
                </div>
              </template>
            </StickyHeader>

            <ConfigModalMobile
              v-model="isModalDisplayed"
              is-apply-button-displayed
            >
              <template #header>
                <div class="promotion__mobile-filters__header">
                  <Lang
                    by-key="filter-by"
                    capitalize-first
                  />
                </div>
              </template>

              <div
                class="promotion__mobile-filters__expanded-filter"
              >
                <p class="promotion__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="region"
                    capitalize-first
                  />
                </p>
                <LocationLinkTree
                  :countries="countries"
                  :link-url-base="locationFilterBaseUrl"
                  :general-parent-location="generalParentLocation"
                  :locations-tree="filterLocationsTree"
                  :current-location="location"
                  :link-query="paxLinkQueryParam"
                />
              </div>

              <hr>

              <div
                v-if="promoCategoryNodes.length"
                class="promotion__mobile-filters__expanded-filter"
              >
                <p class="promotion__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="promotion"
                    capitalize-first
                    plural
                  />
                </p>

                <TruncatedList
                  :split-at="7"
                  :data="promoCategoryNodes"
                  expand-button-left
                >
                  <template #default="{ element }">
                    <div
                      class="promotion__mobile-filters__expanded-filter__checkbox-node"
                      :class="{'selected': element.value}"
                    >
                      <input
                        :id="`category-checkbox-${element.id}`"
                        v-model="element.value"
                        type="checkbox"
                        @change="onPromoCategoryNodeClick"
                      >
                      <label :for="`category-checkbox-${element.id}`">
                        <Lang
                          :by-raw="element.texts"
                        />
                      </label>
                    </div>
                  </template>
                </TruncatedList>
              </div>

              <hr>

              <div
                v-if="treatmentCategoryNodes.length"
                class="promotion__mobile-filters__expanded-filter"
              >
                <p class="promotion__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="treatment"
                    capitalize-first
                    plural
                  />
                </p>

                <TruncatedList
                  :split-at="7"
                  :data="treatmentCategoryNodes"
                  expand-button-left
                >
                  <template #default="{ element }">
                    <div
                      class="promotion__mobile-filters__expanded-filter__checkbox-node"
                      :class="{'selected': element.value}"
                    >
                      <input
                        :id="`category-checkbox-${element.id}`"
                        v-model="element.value"
                        type="checkbox"
                        @change="onTreatmentCategoryNodeClick"
                      >
                      <label :for="`category-checkbox-${element.id}`">
                        <Lang
                          :by-raw="element.texts"
                        />
                      </label>
                    </div>
                  </template>
                </TruncatedList>
              </div>

              <hr>

              <div class="promotion__mobile-filters__expanded-filter">
                <p class="promotion__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="person"
                    capitalize-first
                    plural
                  />
                </p>

                <PaxInlineSelector />
              </div>
            </ConfigModalMobile>
          </div>
        </div>
      </div>

      <div class="promotion__service-list">
        <div class="promotion__service-list__header">
          <div>
            <h2 class="promotion__service-list__header__subtitle">
              {{ subtitle }}
            </h2>
          </div>

          <div>
            <SelectBox
              id="promotion-sorter"
              variant="minified"
              :data="sortOptions"
              display-prop="label"
              class="promotion__service-list__header__sort-selector"
              @input="pushSortOrder"
            >
              <template #label>
                <Lang
                  by-key="sort-by"
                  capitalize-first
                />: <span class="promotion__service-list__header__sort-selector__label">{{
                  selectedSortOptionLabel }}</span>
              </template>
            </SelectBox>
          </div>
        </div>

        <TruncatedList
          v-if="servicesView.length"
          :split-at="24"
          :data="servicesView"
          desktop-table-col-2
        >
          <template #default="{ element }">
            <ServiceListItem
              :service="element"
              :spa="getSpaByUuid(element.spaUUID)"
              show-spa-details
              :selected-pax="selectedPax"
              :has-selected-pax="hasSelectedPax"
            />
          </template>
        </TruncatedList>

        <div
          v-else
          class="promotion__service-list__no-promos"
        >
          <p class="promotion__service-list__no-promos__header">
            <Lang
              by-key="no-available-promotions"
              capitalize-first
            />
          </p>
          <p>
            <Lang
              by-key="no-available-promotions-secondary"
              capitalize-first
            />
          </p>
        </div>
      </div>
    </div>

    <PromotionLargeDescription
      v-if="isLargeDescriptionShown"
      :promotion="promotion"
      :location="location"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import PromotionSmallDescription from '~/core/components/promotion/PromotionSmallDescription.vue'
import PromotionLargeDescription from '~/core/components/promotion/PromotionLargeDescription.vue'

import TruncatedList from '~/core/components/shared/TruncatedList.vue'
import ServiceListItem from '~/core/components/spa-service/atom/ServiceListItem.vue'
import StickyHeader from '~/core/components/shared/StickyHeader.vue'
import Card from '~/core/components/shared/Card.vue'
import Badge from '~/core/components/shared/Badge.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import LocationLinkTree from '~/core/components/location/LocationLinkTree.vue'
import ConfigModalMobile from '~/core/components/shared/form/ConfigModalMobile.vue'
import PaxInlineSelector from '~/core/components/shared/form/PaxInlineSelector.vue'
import SessionLocationSelector from '~/core/components/menu/session-location/SessionLocationSelector.vue'

import HopperIcon from '~/core/components/shared/icons/Hopper.icon.vue'

import { useSorter } from '~/core/composable/spa/useSorter'
import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { getPromotionMetadata, getTitleAndDescription } from '~/core/composable/promotion/usePromotionPageHeader'

import { useMultiPromoFilter } from '~/core/composable/spa/useMultiPromoFilter'
import { useMultiTreatmentFilter } from '~/core/composable/spa/useMultiTreatmentFilter'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

import { Tag } from '~/core/ts/entity/Tag'
import { Promotion } from '~/core/ts/entity/Promotion'
import type { Location, LocationTree } from '~/core/ts/entity/Location'
import type { PromotionLanding } from '~/core/ts/entity/PromotionLanding'
import { ServiceCollection, type Service } from '~/core/ts/entity/Service'
import type { Spa } from '~/core/ts/entity/Spa'
import { capitalizeAllWords, capitalizeFirstWord, joinPaths } from '~/core/ts/util/string'
import { translateByKey } from '~/core/ts/util/translate'
import { CheckBoxNode } from '~/core/ts/entity/CheckBoxNode'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import type { Treatment } from '~/core/ts/entity/Treatment'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    PromotionSmallDescription,
    PromotionLargeDescription,
    TruncatedList,
    ServiceListItem,
    StickyHeader,
    Card,
    Badge,
    SelectBox,
    LocationLinkTree,
    ConfigModalMobile,
    PaxInlineSelector,
    SessionLocationSelector,
    HopperIcon,
  },

  async setup() {
    const route = useRoute()
    const runtimeConfig = useRuntimeConfig()

    const sessionLocation = useSessionLocation()

    const {
      selectedPax,
      hasSelectedPax,
      addOneToPax,
      subOneToPax,
      selectedPaxValue,
    } = usePaxSelector(ref([]), '0')

    const paxLinkQueryParam = computed(() => {
      if (!hasSelectedPax.value) {
        return {}
      }

      return { pax: selectedPax.value.value }
    })

    const recommendedText = capitalizeAllWords(translateByKey('recommended', true))
    const cheapestServiceText = capitalizeAllWords(translateByKey('cheapest-service'))
    const mostExpensiveServiceText = capitalizeAllWords(translateByKey('most-expensive-service'))

    const defaultSortOrder = (() =>  {
      if (route.path.includes('regalos-profesores')) {
        return 'recommended'
      }

      return 'cheapest'
    })()

    const {
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel
    } = useSorter([
      { label: cheapestServiceText, value: 'cheapest' },
      { label: mostExpensiveServiceText, value: 'more_expensive' },
      { label: recommendedText, value: 'recommended' },
    ], defaultSortOrder)

    const promoCategoryNodes = ref<CheckBoxNode[]>([])
    const {
      selectedPromos,
      onPromoCategoryNodeClick,
    } = useMultiPromoFilter(promoCategoryNodes)

    const treatmentCategoryNodes = ref<CheckBoxNode[]>([])
    const {
      selectedTreatments,
      onTreatmentCategoryNodeClick,
    } = useMultiTreatmentFilter(treatmentCategoryNodes)

    // --- Obtener datos
    const countries = await useFetchProxy<Array<Location>>(
      '/api/locations/locations-by-type',
      { method: 'post', body: { type: 'country' } },
    )

    const uri = route.path
    const promotionLanding = await useFetchProxy<PromotionLanding>(
      '/api/promotions/promotion-landing-by-uri',
      { method: 'post', body: { uri } })

    const promotion =  await useFetchProxy<Promotion>(
      '/api/promotions/promotion-by-uuid', { method: 'post', body: { uuid : promotionLanding.tagUuid } })

    const services = await useFetchProxy<Service[]>(
      '/api/services/services-by-uuid-list', { method: 'post', body: { uuids: promotionLanding.payload } })

    const spaUUIDsDict: Record<string, string> = {}
    services.forEach((service) => spaUUIDsDict[service.spaUUID] = service.spaUUID)
    const spaUUIDs = Object.keys(spaUUIDsDict)

    const spas = await useFetchProxy<Spa[]>(
      '/api/spas/spa-list-by-uuid-list-no-services', { method: 'post', body: { uuids: spaUUIDs } })

    const spasByUuid: Record<string, Spa> = {}
    spas.forEach((spa) => spasByUuid[spa.uuid] = spa)

    // Extra promos
    const extraPromosDict: Record<string, Tag> = {}
    services.forEach((service) => {
      service.promotionTags.forEach((serviceTag) => {
        if (serviceTag.uuid !== promotion.uuid) {
          extraPromosDict[serviceTag.uuid] = serviceTag
        }
      })
    })

    promoCategoryNodes.value = Promotion.filterByActiveFest(Object.values(extraPromosDict))
      .sort((promoA, promoB) => promoA.title.localeCompare(promoB.title))
      .map((promotionTag) => {
        const texts: CheckBoxNode['texts'] = {}
        Object.entries(promotionTag.texts).forEach(([key, value]) => texts[key] = value.title)

        return CheckBoxNode.copy({
          id: promotionTag.uuid,
          label: capitalizeAllWords(promotionTag.title),
          texts,
          value: false,
          metadata: { promotionTag }
        })
      })

    // Tratamientos
    const treatments = await useFetchProxy<Array<Treatment>>('/api/treatments/treatment-list', {})
    treatmentCategoryNodes.value = treatments
      .sort((treatmentA, treatmentB) => treatmentA.title.localeCompare(treatmentB.title))
      .filter((treatment) => {
        const collection = new ServiceCollection(services)
        collection.filterByTreatmentUuid(treatment.uuid)

        return !!collection.services.length
      })
      .map((treatment) => {
        return CheckBoxNode.copy({
          id: treatment.uuid,
          label: capitalizeAllWords(treatment.title),
          value: false,
          metadata: { treatment }
        })
      })

    // Localización
    let location: Location | null = null
    let generalParentLocation: Location | null = null
    const filterLocationsTree: LocationTree[] = []

    if (promotionLanding.locationUuid) {
      location = await useFetchProxy<Location>(
        '/api/locations/location-by-uuid', {
          method: 'post',
          body: { uuid: promotionLanding.locationUuid },
          key: 'Landing:' + promotionLanding.locationUuid
        })

      if (location && location.parentUUID) {
        const trees = await useFetchProxy<LocationTree[]>(
          '/api/promotions/location-tree-siblings-by-parent-uuid',
          {
            method: 'post',
            body: {
              uuid: location.parentUUID,
              tagUuid: promotion.uuid
            },
          })
        trees.forEach((tree) => filterLocationsTree.push(tree))

        generalParentLocation = await useFetchProxy<Location>(
          '/api/locations/location-by-uuid', {
            method: 'post',
            body: {
              uuid: location.parentUUID
            },
            key: location.parentUUID
          })
      }
    }

    // No se ha obtenido ninguna localización, esto ocurre en la landing de una promo.
    //   Eg. /.../circuito/
    // Obtenemos las localizaciones de alto nivel
    if (!filterLocationsTree.length) {
      const locResponse = await useFetch<LocationTree[]>(
        '/api/promotions/location-tree-with-no-parent',
        {
          method: 'post',
          body: {
            tagUuid: promotion.uuid,
            lang: runtimeConfig.public.language,
          },
          // key: promotion.uuid
        }
      )
      const trees = locResponse.data.value as LocationTree[]
      trees.forEach((tree) => filterLocationsTree.push(tree))
    }

    // --- Fin. Obtener datos

    const servicesView = computed(() => {
      const collection = new ServiceCollection(services.slice())

      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (!location && selectedCountry) {
        collection.filterByCountry(selectedCountry)
      }

      // Filtro por pareja/no pareja
      if (selectedPax.value.value !== '0') {
        const pax = Number(selectedPax.value.value)
        collection.filterByPaxValue(pax)
      }

      if (selectedPromos.value.length) {
        const uuids = selectedPromos.value.map((node) => node.id)
        collection.filterByPromoCategoryUuids(uuids)
      }

      if (selectedTreatments.value.length) {
        const uuids = selectedTreatments.value.map((node) => node.id)
        collection.filterByTreatmentUuids(uuids)
      }

      switch (sortOrder.value.value) {
        case 'cheapest':
          collection.sortByCheaper(new Date())
          break
        case 'more_expensive':
          collection.sortByMostExpensive(new Date())
          break
        case 'recommended':
          collection.sortByRecommended()
          break
      }

      if (promotion) {
        collection.sortByPromotion(promotion)
      }

      return collection.services
    })

    function getSpaByUuid(uuid: string): Spa {
      return spasByUuid[uuid]
    }

    const firstServiceImage = (() => {
      if (services.length === 0) {
        return ''
      }

      if (services[0].images.length === 0 ) {
        return ''
      }

      return services[0].images[0].url
    })()

    useHead(getPromotionMetadata(uri, promotion, location, firstServiceImage))

    const foundTexts = capitalizeFirstWord(translateByKey('found', false))
    const foundTextp = capitalizeFirstWord(translateByKey('found', true))

    const serviceTexts = capitalizeFirstWord(translateByKey('service', false))
    const serviceTextp = capitalizeFirstWord(translateByKey('service', true))

    const { title } = getTitleAndDescription(promotion, location)
    const subtitle = computed(() => {
      if (servicesView.value.length === 1) {
        return `${foundTexts} 1 ${serviceTexts}`
      }

      return `${foundTextp} ${servicesView.value.length} ${serviceTextp}`
    })

    const isModalDisplayed = ref(false)
    function setIsModalDisplayed(value: boolean) {
      isModalDisplayed.value = value
    }

    const locationFilterBaseUrl = (() => {
      let baseurl = runtimeConfig.public.urls.promotionBaseUrl

      if (promotion) {
        return joinPaths(baseurl, promotion.slug)
      }

      return baseurl
    })()

    const isLargeDescriptionShown = location === null ? true : false

    // Valores para los Richsnippets de Google
    let sum = 0
    let count = 0
    let best = 0
    services.forEach((service) => {
      const spa = spasByUuid[service.spaUUID]
      if (!spa) { return }
      if (!spa.score) { return }
      sum += spa.score
      count ++

      if (spa.score > best) {
        best = spa.score
      }
    })

    const rawScore = count ? (sum / count) : 0

    // NOTE: Recomendación de David Rodríguez. Si no ha nota que mostrar, ponemos 4 por defecto.
    const score = rawScore ? rawScore.toFixed(2) : '4'
    const maxScore = 5
    const reviewCount = Math.max(count, 1)

    return {
      title,
      subtitle,
      promotion,
      promotionLanding,
      servicesView,
      location,

      // Richsnippets
      score,
      maxScore,
      reviewCount,

      isLargeDescriptionShown,

      // Filtro de regiones.
      countries,
      generalParentLocation,
      filterLocationsTree,
      locationFilterBaseUrl,

      // Sorter
      sortOptions,
      pushSortOrder,
      selectedSortOptionLabel,

      // Extra promotion filter
      promoCategoryNodes,
      selectedPromos,
      onPromoCategoryNodeClick,

      // Extra treatment filter
      treatmentCategoryNodes,
      selectedTreatments,
      onTreatmentCategoryNodeClick,

      // Pax selector
      selectedPax,
      selectedPaxValue,
      hasSelectedPax,
      addOneToPax,
      subOneToPax,
      paxLinkQueryParam,

      // Mobile modal
      isModalDisplayed,
      setIsModalDisplayed,

      getSpaByUuid,
    }
  },
})
</script>

<style lang="scss" scoped>
.promotion {
  @apply py-1;

  &__header {
    @apply px-2;
    @apply pb-2;

    @screen lg {
      @apply px-0;
    }
  }

  &__title {
    @apply mt-20p mb-20p;
    @apply text-xl;
    @apply text-spl-dark;
    @apply font-semibold;

    @apply px-2;

    @screen lg {
      @apply text-3xl;
      @apply px-0;
    }
  }

  &__list-wrapper {
    @apply mt-2;
    @apply py-4;

    @screen lg {
      @apply gap-3;
      @apply grid;
      grid-template-columns: 25% 75%;
    }
  }

  &__mobile-selected-filters {
    @apply px-4 pt-1 pb-4;

    &__row {
      @apply py-1;
    }

    &__category {
      @apply m-1;
    }
  }

  &__mobile-filters {
    &__header {
      &__title {
        @apply text-xl;
        @apply text-spl-dark;
        @apply py-4;
        @apply px-2;
        @apply font-semibold;
      }

      &__controls {
        @apply py-2;
        @apply flex justify-end;
      }
    }

    &__button {
      @apply flex items-center;
      @apply text-sm;

      @apply px-4;
      @apply py-2;
      @apply border-r-1;

      &__icon {
        @apply mr-1;
      }
    }

    &__expanded-filter {
      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__checkbox-node {
        input,
        label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }
        }

        &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
        }

        &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }

      @apply pb-2 mb-2;
    }

    &__sort-selector {
      @apply text-sm;
      @apply px-4;
      @apply py-2;

      &__label {
        @apply font-bold;
        @apply italic;
      }
    }
  }

  &__filters {
    @apply py-4;
    @apply px-4;

    &__title {
      @apply text-lg;
      @apply font-bold;
      @apply text-spl-dark;

      @apply w-full;
      @apply py-1;
      @apply my-1;
      @apply border-b-1;
      @apply border-spl-secondary;
    }

    &__expanded-filter {
      @apply py-2;

      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__checkbox-node {

        input,
        label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }
      }

      &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
      }

      &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }
    }

    &__filter-selector {
      @apply my-2;
    }
  }

  &__service-list {
    &__header {
      @apply hidden;

      @screen lg {
        @apply flex;
        @apply justify-between;
        @apply items-center;
      }

      @apply px-2 pb-1;

      &__subtitle {
        @apply text-spl-dark;
        @apply text-sm;
      }

      &__sort-selector {
        @apply my-2;

        &__label {
          @apply font-bold;
          @apply italic;
        }
      }
    }

    &__no-promos {
      @apply text-center;
      @apply py-6;

      @apply mx-2;
      @apply my-8;

      @screen lg {
        @apply my-2;
      }

      @apply border;
      @apply border-spl-gray-1;
      border-radius: 16px;

      &__header {
        @apply font-bold;
        @apply text-spl-acent-dark;
      }
    }
  }
}
</style>
