
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/index.vue?macro=true";
import { default as indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/PromotionHome.page.vue?macro=true";
import { default as Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta } from "/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue?macro=true";
export default [
  {
    name: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta?.name ?? "index",
    path: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta?.path ?? "/",
    props: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta?.props ?? false,
    meta: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta || {},
    alias: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta?.alias || [],
    redirect: indexLXmSF_45wKX2tHvcNxTmzJGfF5bRJ888X_45IrPA1VG9ZeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/index.vue")
  },
  {
    name: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta?.name ?? "lang",
    path: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta?.path ?? "/:lang?",
    props: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta?.props ?? false,
    meta: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta || {},
    alias: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta?.alias || [],
    redirect: indexMtCh1NquU3RmRpm6J77lqM8W_GOZUTKFoDjklFhVPT8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta?.name ?? "lang-voucher-start",
    path: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta?.path ?? "/:lang?/voucher/start",
    props: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta?.props ?? false,
    meta: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta || {},
    alias: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta?.alias || [],
    redirect: startfGBIWhhs7sqTn2D21c1yCiOWydJjluQs5hGuY9NtTwYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta?.name ?? "lang-purchase-start",
    path: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta?.path ?? "/:lang?/purchase/start",
    props: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta?.props ?? false,
    meta: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta || {},
    alias: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta?.alias || [],
    redirect: start_tPeFwDs8Xb4RSgP8Ke5XSrZFQqoF10kyFHBPETRAPwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta?.props ?? false,
    meta: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta || {},
    alias: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta?.alias || [],
    redirect: checkoutPf7tvdq_60WxiRckl91UeeF82DfhcYA5tIG4tCC0O4AMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta?.props ?? false,
    meta: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta || {},
    alias: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta?.alias || [],
    redirect: koWStvAc_45lMtb0ri2s3Ibp5F0HabPJeihE8pUeu2_45eAb0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta?.props ?? false,
    meta: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta || {},
    alias: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta?.alias || [],
    redirect: confirmationbuOSI8_MQHPjfvjRFnumDZfs_Lr_45vOScfIu_6ZvLziUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta?.props ?? false,
    meta: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta || {},
    alias: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta?.alias || [],
    redirect: confirmationkdGI_45g90E_f7m1haWpORk34pPwQx6GWdqa25a11PYoUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta?.props ?? false,
    meta: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta || {},
    alias: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta?.alias || [],
    redirect: buyerTkq1aZzPnBZKbGTumpFL3sF3LuSKnJxeqaMesku6aPoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta?.props ?? false,
    meta: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta || {},
    alias: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta?.alias || [],
    redirect: buyerYiavOu_453pqWElKE3Uc8m8lXeA6gjowcNYRlPJeK7n4kMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta?.props ?? false,
    meta: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta || {},
    alias: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta?.alias || [],
    redirect: paymentZZUQlJq0FjkXSgX44AfnMs5jAvVPlxJVIsgR6IRDzRoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta?.props ?? false,
    meta: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta || {},
    alias: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta?.alias || [],
    redirect: challengeb9n8cS2ml6hZ67A5LwwMG66cWC37pLgIjYaGM_lWHcUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta?.props ?? false,
    meta: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta || {},
    alias: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta?.alias || [],
    redirect: paymentPTKByGxIwosTgdwYQtfsjNn1LI3Qi7DMSsydydq5V_458Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta?.name ?? "Promotion home",
    path: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta?.path ?? "/promociones/",
    props: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta?.props ?? false,
    meta: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta || {},
    alias: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta?.alias || [],
    redirect: PromotionHome_46pageV_nF_foBKm1Z54jSzgZ_451PDuzWie81qEceAunTPTgTEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/PromotionHome.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 0",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 3",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 4",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 5",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 6",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 7",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 8",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 9",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 10",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 11",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 12",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 13",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 14",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 15",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 16",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 17",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 18",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 19",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 20",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 21",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 22",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 23",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 24",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 25",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 26",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 27",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 28",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 29",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 30",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 31",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 32",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 33",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 34",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 35",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 36",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 37",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 38",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 39",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 40",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 41",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 42",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/malaga/mijas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 43",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 44",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 45",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 46",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 47",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 48",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 49",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 50",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 51",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 52",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 53",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 54",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 55",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 56",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 57",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 58",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 59",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/black-friday/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 60",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 61",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 62",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 63",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 64",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 65",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 66",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 67",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 68",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 69",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 70",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 71",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 72",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 73",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 74",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 75",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 76",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 77",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 78",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 79",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 80",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 81",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 82",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 83",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 84",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 85",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/castellon/la-vilavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 86",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 87",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 88",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 89",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 90",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 91",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 92",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 93",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 94",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 95",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 96",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 97",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 98",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 99",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 100",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 101",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 102",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 103",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 104",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 105",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 106",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 107",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 108",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 109",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 110",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 111",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 112",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 113",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 114",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 115",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 116",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 117",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 118",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 119",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 120",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 121",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 122",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 123",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 124",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 125",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 126",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 127",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 128",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 129",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 130",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 131",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 132",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 133",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 134",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 135",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 136",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 137",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 138",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 139",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 140",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 141",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 142",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 143",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 144",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 145",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 146",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 147",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 148",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 149",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 150",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 151",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 152",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-navidad/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 153",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 154",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 155",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/a-coruna/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 156",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/a-coruna/carnota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 157",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 158",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 159",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 160",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 161",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 162",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 163",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 164",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 165",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 166",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 167",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 168",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/alicante/teulada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 169",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 170",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 171",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 172",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 173",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 174",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 175",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 176",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 177",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 178",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 179",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 180",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 181",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 182",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 183",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 184",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 185",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 186",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 187",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 188",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 189",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 190",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 191",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 192",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cantabria/la-hermida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 193",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 194",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/castellon/montanejos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 195",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 196",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 197",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 198",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 199",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 200",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 201",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 202",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 203",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 204",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 205",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 206",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 207",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 208",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 209",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 210",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 211",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 212",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/faro/monchique/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 213",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 214",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 215",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 216",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 217",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 218",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/castello-dempuries/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 219",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 220",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/lloret-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 221",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/girona/toses/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 222",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 223",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 224",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 225",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 226",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 227",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 228",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 229",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 230",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 231",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 232",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 233",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 234",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 235",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 236",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/huesca/monzon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 237",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 238",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/jaen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 239",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/jaen/ubeda/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 240",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 241",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 242",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 243",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 244",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lleida/caldes-de-boi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 245",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 246",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 247",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 248",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 249",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 250",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 251",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 252",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 253",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 254",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 255",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 256",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 257",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 258",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/felanitx/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 259",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/llucmajor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 260",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/muro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 261",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 262",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 263",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 264",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/mallorca/son-servera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 265",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 266",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 267",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/murcia/la-manga-del-mar-menor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 268",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 269",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/parroquia-de-escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 270",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 271",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 272",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 273",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pontevedra/tui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 274",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 275",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 276",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 277",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 278",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 279",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 280",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 281",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 282",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 283",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 284",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 285",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 286",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 287",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 288",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 289",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 290",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 291",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 292",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 293",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 294",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 295",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 296",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 297",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-comida/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 298",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 299",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 300",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 301",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 302",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 303",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 304",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 305",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 306",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 307",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 308",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 309",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 310",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 311",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 312",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 313",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 314",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 315",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 316",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 317",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 318",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 319",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 320",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 321",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 322",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 323",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 324",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 325",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 326",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 327",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 328",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 329",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-spa-y-comida-navidad/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 330",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 331",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia-navidad/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 332",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia-navidad/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 333",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia-navidad/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 334",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 335",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 336",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 337",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 338",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 339",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 340",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 341",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 342",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 343",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 344",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 345",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 346",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 347",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 348",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 349",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 350",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/granada/niguelas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 351",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 352",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 353",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 354",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 355",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 356",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 357",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 358",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 359",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 360",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 361",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 362",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 363",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 364",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-estancia/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 365",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 366",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 367",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 368",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 369",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 370",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 371",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 372",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 373",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 374",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 375",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 376",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 377",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 378",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 379",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 380",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 381",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 382",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 383",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 384",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 385",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 386",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 387",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 388",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 389",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 390",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 391",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 392",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 393",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 394",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 395",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 396",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 397",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 398",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 399",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 400",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 401",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 402",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 403",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 404",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 405",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 406",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 407",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 408",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 409",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 410",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 411",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 412",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 413",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 414",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 415",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 416",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 417",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 418",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 419",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 420",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 421",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 422",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 423",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 424",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 425",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 426",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 427",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 428",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 429",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 430",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 431",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 432",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 433",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 434",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 435",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje-navidad/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 436",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 437",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 438",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/a-coruna/carnota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 439",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 440",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 441",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 442",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 443",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 444",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 445",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 446",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 447",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 448",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 449",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 450",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 451",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 452",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 453",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 454",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 455",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 456",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 457",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 458",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 459",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/asturias/langreo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 460",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 461",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 462",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 463",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 464",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 465",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 466",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 467",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 468",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 469",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 470",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 471",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 472",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 473",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 474",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/barcelona/vallromanes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 475",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 476",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 477",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 478",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 479",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 480",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 481",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 482",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 483",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 484",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 485",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 486",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 487",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 488",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 489",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 490",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 491",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 492",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 493",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 494",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 495",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 496",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 497",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 498",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 499",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 500",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 501",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 502",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 503",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 504",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 505",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 506",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 507",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 508",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 509",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 510",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 511",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 512",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 513",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/evora/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 514",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 515",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 516",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 517",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/faro/monchique/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 518",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/faro/portimao/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 519",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 520",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 521",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 522",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 523",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 524",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/castello-dempuries/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 525",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 526",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 527",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/girona/toses/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 528",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 529",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 530",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 531",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 532",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 533",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 534",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 535",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 536",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 537",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/granada/monachil/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 538",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 539",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 540",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 541",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 542",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/guipuzcoa/hondarribia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 543",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 544",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 545",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 546",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 547",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 548",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huesca/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 549",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 550",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 551",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/jaen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 552",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/jaen/ubeda/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 553",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 554",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 555",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 556",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 557",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 558",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 559",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leiria/monte-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 560",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leiria/vestiaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 561",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 562",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/leon/astorga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 563",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 564",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 565",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 566",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 567",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 568",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lleida/caldes-de-boi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 569",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 570",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 571",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lugo/guitiriz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 572",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lugo/panton/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 573",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 574",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 575",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/madeira/funchal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 576",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 577",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 578",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 579",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/benalmadena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 580",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 581",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 582",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 583",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 584",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/malaga/torremolinos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 585",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 586",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 587",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 588",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 589",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/felanitx/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 590",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/llucmajor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 591",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 592",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/muro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 593",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 594",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 595",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 596",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 597",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 598",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 599",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 600",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/oporto/vila-do-conde/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 601",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 602",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 603",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/parroquia-de-escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 604",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 605",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 606",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 607",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 608",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 609",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 610",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/region-de-madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 611",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 612",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 613",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 614",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 615",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 616",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 617",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 618",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 619",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 620",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 621",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 622",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tarragona/torroja-del-priorat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 623",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 624",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 625",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 626",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 627",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 628",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 629",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 630",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 631",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 632",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 633",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 634",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 635",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 636",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 637",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 638",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 639",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 640",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 641",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 642",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 643",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 644",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 645",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-masaje/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 646",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 647",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 648",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 649",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 650",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 651",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 652",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 653",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 654",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 655",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 656",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 657",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 658",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 659",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 660",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 661",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 662",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 663",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 664",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 665",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual-navidad/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 666",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 667",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 668",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 669",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 670",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 671",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 672",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 673",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 674",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 675",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 676",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 677",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 678",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 679",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 680",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 681",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 682",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 683",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 684",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 685",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 686",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 687",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 688",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 689",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 690",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 691",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 692",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 693",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 694",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 695",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 696",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 697",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 698",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 699",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 700",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 701",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 702",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 703",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 704",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 705",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 706",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 707",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 708",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 709",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 710",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 711",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 712",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 713",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 714",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 715",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 716",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 717",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 718",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 719",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 720",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 721",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/evora/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 722",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 723",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 724",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/faro/monchique/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 725",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/faro/portimao/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 726",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 727",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 728",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 729",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 730",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 731",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 732",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 733",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 734",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 735",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 736",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 737",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 738",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 739",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 740",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 741",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 742",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 743",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 744",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/guipuzcoa/hondarribia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 745",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 746",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 747",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 748",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 749",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 750",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 751",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 752",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 753",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 754",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 755",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 756",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 757",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/leiria/monte-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 758",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 759",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/leon/astorga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 760",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 761",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 762",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 763",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 764",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 765",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 766",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 767",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/madeira/funchal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 768",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 769",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 770",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 771",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 772",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 773",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 774",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/malaga/torremolinos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 775",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 776",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 777",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 778",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 779",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/llucmajor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 780",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 781",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/muro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 782",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 783",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 784",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/mallorca/son-servera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 785",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 786",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 787",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 788",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 789",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 790",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 791",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 792",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 793",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 794",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 795",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 796",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/region-de-madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 797",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 798",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 799",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 800",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 801",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 802",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 803",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 804",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 805",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 806",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 807",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 808",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 809",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 810",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 811",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 812",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 813",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/viana-do-castelo/caminha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 814",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 815",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 816",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 817",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 818",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/vizcaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 819",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito-y-ritual/vizcaya/urduna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 820",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 821",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 822",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/a-coruna/carnota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 823",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 824",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 825",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 826",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 827",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 828",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 829",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 830",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 831",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 832",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 833",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 834",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 835",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 836",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 837",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 838",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/alicante/teulada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 839",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 840",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 841",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 842",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 843",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 844",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 845",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 846",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/asturias/langreo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 847",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 848",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 849",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 850",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 851",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 852",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 853",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 854",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 855",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 856",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 857",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 858",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 859",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 860",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 861",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/barcelona/vallromanes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 862",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 863",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 864",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 865",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 866",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 867",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 868",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 869",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 870",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 871",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/rota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 872",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 873",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 874",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 875",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 876",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 877",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cantabria/la-hermida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 878",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cantabria/medio-cudeyo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 879",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 880",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 881",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castellon/la-vilavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 882",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castellon/montanejos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 883",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 884",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 885",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 886",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 887",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 888",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 889",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 890",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 891",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 892",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 893",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 894",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 895",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 896",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 897",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 898",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 899",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 900",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 901",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 902",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 903",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 904",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 905",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 906",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/evora/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 907",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 908",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 909",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 910",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/faro/monchique/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 911",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/faro/monte-gordo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 912",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/faro/portimao/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 913",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 914",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 915",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 916",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 917",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 918",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 919",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 920",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/lloret-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 921",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 922",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/girona/toses/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 923",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 924",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 925",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 926",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 927",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 928",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 929",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 930",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 931",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 932",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 933",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 934",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/loja/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 935",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 936",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 937",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 938",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/guadalajara/brihuega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 939",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 940",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 941",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 942",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 943",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 944",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 945",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 946",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 947",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huesca/monzon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 948",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 949",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 950",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/jaen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 951",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/jaen/ubeda/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 952",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 953",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 954",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 955",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 956",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 957",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 958",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leiria/monte-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 959",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leiria/nazare/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 960",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leiria/vestiaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 961",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 962",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/leon/astorga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 963",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 964",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 965",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 966",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 967",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 968",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lleida/caldes-de-boi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 969",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 970",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lleida/vielha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 971",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 972",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lugo/panton/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 973",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 974",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 975",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/madeira/funchal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 976",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 977",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 978",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 979",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/benalmadena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 980",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 981",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 982",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 983",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 984",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/mijas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 985",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/malaga/torremolinos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 986",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 987",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 988",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 989",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/felanitx/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 990",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/llucmajor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 991",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/muro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 992",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 993",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 994",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/mallorca/son-servera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 995",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 996",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 997",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 998",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 999",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/murcia/la-manga-del-mar-menor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1000",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/murcia/los-alcazares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1001",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1002",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1003",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/oporto/vila-do-conde/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1004",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1005",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/parroquia-de-escaldes-engordany/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1006",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1007",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1008",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1009",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pontevedra/tui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1010",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1011",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1012",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1013",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/region-de-madeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1014",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1015",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1016",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1017",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1018",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1019",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1020",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1021",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1022",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1023",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1024",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1025",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/soria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1026",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1027",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1028",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1029",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1030",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1031",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tarragona/torroja-del-priorat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1032",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1033",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1034",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1035",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1036",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1037",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1038",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1039",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1040",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1041",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1042",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1043",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1044",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1045",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1046",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1047",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1048",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1049",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valladolid/olmedo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1050",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1051",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/valladolid/san-bernarndo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1052",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1053",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1054",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1055",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1056",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1057",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1058",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/vizcaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1059",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/circuito/vizcaya/urduna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1060",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1061",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1062",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1063",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1064",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1065",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1066",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1067",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1068",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1069",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1070",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1071",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1072",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1073",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1074",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1075",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1076",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1077",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1078",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1079",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/faro/monte-gordo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1080",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1081",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1082",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1083",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1084",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1085",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1086",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1087",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1088",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1089",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1090",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1091",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1092",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1093",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1094",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1095",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1096",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1097",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/felanitx/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1098",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/llucmajor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1099",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1100",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1101",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1102",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1103",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1104",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1105",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1106",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1107",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1108",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1109",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/daypass-spa/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1110",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1111",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1112",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1113",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1114",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1115",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1116",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1117",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1118",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1119",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1120",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1121",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1122",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1123",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1124",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1125",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1126",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1127",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1128",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1129",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1130",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1131",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1132",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1133",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1134",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1135",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1136",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1137",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1138",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1139",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1140",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1141",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1142",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1143",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1144",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1145",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1146",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1147",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1148",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1149",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1150",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1151",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1152",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1153",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1154",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1155",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1156",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1157",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1158",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1159",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1160",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1161",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1162",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1163",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1164",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1165",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1166",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1167",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/malaga/mijas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1168",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1169",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1170",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1171",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1172",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1173",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1174",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1175",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1176",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1177",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1178",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1179",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1180",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1181",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1182",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1183",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1184",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/descuentos/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1185",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1186",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-canarias/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1187",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-canarias/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1188",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-canarias/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1189",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1190",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1191",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1192",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1193",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1194",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1195",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1196",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1197",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1198",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1199",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1200",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1201",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1202",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1203",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1204",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1205",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1206",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1207",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1208",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1209",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1210",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1211",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1212",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1213",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1214",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1215",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1216",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1217",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1218",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1219",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1220",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1221",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1222",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1223",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/castellon/la-vilavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1224",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1225",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1226",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1227",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1228",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1229",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1230",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1231",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1232",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1233",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1234",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1235",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1236",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1237",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1238",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1239",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1240",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1241",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1242",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1243",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1244",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1245",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1246",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1247",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1248",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1249",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1250",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1251",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1252",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/costa-occidental/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1253",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1254",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1255",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1256",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huesca/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1257",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1258",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1259",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1260",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1261",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1262",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1263",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1264",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1265",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1266",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1267",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1268",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1269",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1270",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1271",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1272",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1273",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1274",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1275",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1276",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1277",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1278",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1279",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1280",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1281",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1282",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1283",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1284",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1285",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1286",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1287",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1288",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1289",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1290",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1291",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1292",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1293",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1294",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1295",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1296",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1297",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1298",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1299",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1300",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1301",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1302",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1303",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1304",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1305",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1306",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-de-la-madre/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1307",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1308",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1309",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1310",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1311",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1312",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1313",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1314",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1315",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1316",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1317",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1318",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1319",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1320",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1321",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1322",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1323",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1324",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1325",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1326",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1327",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1328",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1329",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1330",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1331",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1332",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1333",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1334",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1335",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1336",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/castellon/la-vilavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1337",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1338",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1339",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1340",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1341",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1342",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1343",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1344",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1345",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1346",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1347",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1348",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1349",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1350",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1351",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1352",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1353",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1354",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1355",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1356",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1357",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1358",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1359",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1360",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1361",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1362",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1363",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1364",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1365",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1366",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1367",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1368",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huelva/costa-occidental/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1369",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1370",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1371",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huesca/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1372",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1373",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1374",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1375",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1376",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1377",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1378",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1379",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1380",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1381",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1382",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1383",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1384",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1385",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1386",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1387",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1388",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1389",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1390",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1391",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1392",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1393",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1394",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1395",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1396",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1397",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1398",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1399",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1400",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1401",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1402",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1403",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1404",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1405",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1406",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1407",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1408",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1409",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1410",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1411",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1412",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1413",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1414",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1415",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1416",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1417",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1418",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1419",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1420",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1421",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1422",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1423",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/dia-del-padre/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1424",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1425",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1426",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1427",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1428",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1429",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1430",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1431",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1432",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1433",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1434",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1435",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1436",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1437",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1438",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1439",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1440",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1441",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1442",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1443",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1444",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1445",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1446",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1447",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1448",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1449",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1450",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1451",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1452",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1453",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1454",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1455",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1456",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1457",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1458",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1459",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1460",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1461",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1462",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1463",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1464",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1465",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/rota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1466",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1467",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1468",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1469",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1470",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1471",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1472",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/castellon/la-vilavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1473",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/castellon/montanejos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1474",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1475",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1476",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1477",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/ciudad-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1478",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/ciudad-real/almagro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1479",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1480",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1481",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1482",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1483",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1484",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1485",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1486",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1487",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1488",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1489",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1490",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1491",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1492",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1493",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/girona/torrent/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1494",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1495",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1496",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1497",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1498",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1499",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1500",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1501",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1502",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1503",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1504",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/lanjaron/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1505",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/granada/niguelas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1506",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1507",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1508",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1509",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1510",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1511",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1512",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1513",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/costa-occidental/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1514",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1515",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1516",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1517",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1518",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1519",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1520",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1521",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1522",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1523",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1524",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/leon/astorga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1525",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1526",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1527",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1528",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1529",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1530",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1531",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1532",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1533",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1534",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1535",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1536",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1537",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1538",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1539",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/montuiri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1540",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1541",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1542",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1543",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1544",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1545",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1546",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/murcia/los-alcazares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1547",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1548",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1549",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1550",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1551",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1552",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1553",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1554",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1555",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1556",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1557",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1558",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1559",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1560",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1561",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1562",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1563",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1564",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1565",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1566",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1567",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1568",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1569",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1570",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1571",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1572",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1573",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1574",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1575",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1576",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1577",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1578",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1579",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/especial-navidad/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1580",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1581",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1582",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1583",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1584",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1585",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1586",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1587",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1588",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1589",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1590",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1591",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1592",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1593",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1594",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1595",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1596",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1597",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1598",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1599",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1600",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1601",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1602",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1603",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1604",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1605",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1606",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1607",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1608",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1609",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1610",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1611",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1612",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1613",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1614",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1615",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1616",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1617",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1618",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1619",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1620",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1621",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1622",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1623",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1624",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1625",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1626",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1627",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1628",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1629",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1630",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1631",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/faro/portimao/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1632",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1633",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1634",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1635",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1636",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1637",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1638",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1639",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1640",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1641",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1642",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1643",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1644",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1645",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/granada/niguelas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1646",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1647",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1648",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1649",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1650",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huelva/costa-occidental/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1651",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1652",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1653",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1654",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1655",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1656",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1657",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1658",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1659",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1660",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1661",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1662",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1663",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1664",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1665",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1666",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1667",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1668",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1669",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1670",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1671",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1672",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1673",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1674",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1675",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1676",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1677",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1678",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1679",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1680",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1681",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1682",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1683",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1684",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1685",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1686",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1687",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1688",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1689",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1690",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1691",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1692",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1693",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1694",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1695",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1696",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1697",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1698",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1699",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1700",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1701",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1702",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1703",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1704",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1705",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1706",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1707",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1708",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1709",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/masaje-para-dos/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1710",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1711",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1712",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1713",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1714",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1715",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1716",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1717",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1718",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1719",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1720",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/rojales/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1721",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/alicante/teulada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1722",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1723",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1724",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1725",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1726",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1727",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/asturias/langreo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1728",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1729",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1730",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1731",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1732",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1733",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1734",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1735",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1736",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1737",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1738",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1739",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1740",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/rota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1741",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1742",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1743",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1744",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1745",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cantabria/medio-cudeyo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1746",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1747",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1748",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/castellon/montanejos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1749",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1750",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1751",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1752",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1753",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1754",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1755",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1756",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1757",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1758",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1759",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1760",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1761",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1762",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1763",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1764",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1765",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1766",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1767",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1768",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1769",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1770",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1771",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1772",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/loja/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1773",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/monachil/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1774",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/granada/niguelas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1775",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1776",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/guadalajara/brihuega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1777",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1778",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/guipuzcoa/hondarribia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1779",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1780",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1781",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1782",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1783",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1784",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1785",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1786",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1787",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lleida/caldes-de-boi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1788",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lleida/vielha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1789",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1790",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1791",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1792",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1793",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1794",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1795",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1796",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1797",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1798",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/malaga/mijas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1799",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1800",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1801",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1802",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/mallorca/son-servera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1803",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1804",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1805",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/murcia/los-alcazares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1806",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1807",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1808",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1809",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1810",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1811",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1812",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1813",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1814",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1815",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1816",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1817",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1818",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1819",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1820",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/soria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1821",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1822",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1823",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tarragona/torroja-del-priorat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1824",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1825",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1826",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1827",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1828",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1829",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1830",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1831",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1832",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1833",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1834",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valladolid/olmedo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1835",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1836",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/valladolid/san-bernarndo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1837",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1838",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/viana-do-castelo/caminha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1839",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/vizcaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1840",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/recomendados/vizcaya/urduna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1841",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1842",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1843",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1844",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1845",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1846",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1847",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1848",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1849",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1850",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1851",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1852",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1853",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/caceres/caceres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1854",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1855",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1856",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1857",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1858",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1859",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1860",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1861",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1862",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1863",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1864",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1865",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1866",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1867",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1868",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1869",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1870",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1871",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1872",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1873",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1874",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1875",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1876",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1877",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1878",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1879",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1880",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1881",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1882",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1883",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1884",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1885",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1886",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1887",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1888",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1889",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1890",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1891",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1892",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1893",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1894",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1895",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1896",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1897",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/san-valentin/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1898",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1899",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1900",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1901",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1902",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1903",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1904",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1905",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1906",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1907",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1908",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1909",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1910",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-cumpleanos/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1911",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1912",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1913",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1914",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/a-coruna/outes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1915",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1916",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1917",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alava/vitoria-gasteiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1918",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1919",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/albacete/albacete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1920",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1921",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1922",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/altea/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1923",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1924",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1925",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1926",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1927",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1928",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/almeria/alhama-de-almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1929",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/almeria/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1930",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1931",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1932",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1933",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1934",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1935",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1936",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1937",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1938",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1939",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/berga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1940",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/caldes-de-montbui/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1941",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1942",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/la-garriga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1943",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1944",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/barcelona/sant-joan-despi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1945",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1946",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1947",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1948",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1949",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1950",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/grazalema/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1951",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/novo-sancti-petri/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1952",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1953",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1954",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1955",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1956",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cantabria/medio-cudeyo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1957",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1958",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1959",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1960",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1961",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1962",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1963",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1964",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1965",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1966",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1967",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cordoba/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1968",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1969",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1970",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1971",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1972",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1973",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1974",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1975",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1976",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1977",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1978",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1979",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/faro/monchique/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1980",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/faro/portimao/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1981",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1982",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1983",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1984",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1985",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1986",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1987",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/girona/lloret-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1988",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1989",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1990",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/agaete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1991",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1992",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1993",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1994",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1995",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1996",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1997",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1998",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 1999",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2000",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2001",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2002",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2003",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huelva/costa-occidental/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2004",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2005",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2006",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2007",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2008",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2009",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/jaen/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2010",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/jaen/ubeda/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2011",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2012",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2013",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2014",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2015",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2016",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/leiria/monte-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2017",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2018",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2019",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2020",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2021",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2022",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lleida/vielha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2023",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2024",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2025",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2026",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2027",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2028",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2029",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/malaga/fuengirola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2030",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2031",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2032",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2033",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2034",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2035",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/palma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2036",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2037",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/mallorca/soller/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2038",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2039",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2040",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2041",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2042",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2043",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2044",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2045",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2046",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2047",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2048",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2049",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2050",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2051",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2052",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2053",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2054",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2055",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2056",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2057",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2058",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2059",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2060",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2061",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2062",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2063",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tarragona/el-perello/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2064",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2065",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2066",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tarragona/torroja-del-priorat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2067",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2068",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2069",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2070",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/guia-de-isora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2071",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2072",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2073",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2074",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2075",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/tenerife/santa-ursula/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2076",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2077",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2078",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2079",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2080",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2081",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/valencia/paterna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2082",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2083",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2084",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2085",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2086",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2087",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2088",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-en-pareja/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2089",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2090",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2091",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2092",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2093",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2094",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2095",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2096",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2097",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2098",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2099",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2100",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2101",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-nocturno/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2102",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2103",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2104",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2105",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2106",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2107",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2108",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2109",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2110",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/asturias/langreo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2111",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2112",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2113",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2114",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2115",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2116",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2117",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2118",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cantabria/la-hermida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2119",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cantabria/torrelavega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2120",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2121",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2122",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2123",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2124",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2125",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2126",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2127",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2128",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2129",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2130",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2131",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2132",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2133",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2134",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2135",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2136",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2137",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2138",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2139",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2140",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2141",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2142",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2143",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2144",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2145",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/lisboa/torres-vedras/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2146",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2147",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2148",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2149",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2150",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2151",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2152",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2153",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2154",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2155",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2156",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2157",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2158",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2159",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2160",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2161",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2162",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2163",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2164",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2165",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2166",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2167",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2168",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-embarazadas/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2169",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2170",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2171",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2172",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2173",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2174",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2175",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2176",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2177",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2178",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2179",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2180",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2181",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2182",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2183",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2184",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/granada/motril/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2185",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/granada/niguelas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2186",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2187",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2188",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2189",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2190",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2191",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2192",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/oporto/vila-do-conde/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2193",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2194",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2195",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2196",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2197",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2198",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2199",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2200",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2201",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2202",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-grupos/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2203",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2204",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2205",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/a-coruna/carnota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2206",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2207",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2208",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2209",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/alicante/denia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2210",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/alicante/l-alfas-del-pi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2211",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/almeria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2212",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/almeria/roquetas-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2213",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2214",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2215",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2216",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/asturias/langreo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2217",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2218",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2219",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/azores/ponta-delgada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2220",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2221",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2222",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/barcelona/calella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2223",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/barcelona/seva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2224",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/barcelona/sitges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2225",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2226",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2227",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2228",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cadiz/rota/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2229",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2230",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2231",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2232",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cantabria/medio-cudeyo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2233",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/castellon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2234",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/castellon/peniscola/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2235",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2236",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2237",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2238",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2239",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2240",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2241",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cordoba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2242",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/cordoba/lucena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2243",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/distrito-de-faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2244",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/distrito-de-leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2245",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2246",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/distrito-de-setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2247",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/distrito-de-vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2248",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/faro/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2249",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/faro/albufeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2250",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/fuerteventura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2251",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/fuerteventura/antigua/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2252",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/fuerteventura/pajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2253",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2254",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2255",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/girona/caldes-de-malavella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2256",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/girona/llanars/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2257",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/girona/toses/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2258",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2259",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2260",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2261",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2262",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/granada/almunecar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2263",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2264",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2265",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/guadalajara/brihuega/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2266",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2267",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huelva/ayamonte/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2268",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huelva/cartaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2269",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2270",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2271",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/huesca/sallent-de-gallego/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2272",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2273",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2274",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2275",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/leiria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2276",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/leiria/alcobaca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2277",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2278",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/leon/astorga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2279",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lleida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2280",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lleida/caldes-de-boi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2281",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2282",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2283",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lugo/guitiriz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2284",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lugo/panton/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2285",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/lugo/vilalba/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2286",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2287",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2288",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2289",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/malaga/marbella/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2290",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2291",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2292",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2293",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/oporto/vila-do-conde/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2294",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2295",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2296",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/pontevedra/moana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2297",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2298",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2299",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2300",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/region-de-azores/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2301",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2302",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/segovia/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2303",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/setubal/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2304",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/setubal/santiago-do-cacem/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2305",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/soria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2306",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2307",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2308",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tarragona/montbrio-del-camp/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2309",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2310",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2311",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2312",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2313",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2314",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2315",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/toledo/carranque/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2316",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2317",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2318",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valencia/gandia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2319",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2320",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2321",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valladolid/olmedo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2322",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2323",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/valladolid/san-bernarndo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2324",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/vila-real/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2325",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/vila-real/boticas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2326",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/vila-real/ribeira-de-pena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2327",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/vizcaya/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2328",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-para-ninos/vizcaya/urduna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2329",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2330",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/a-coruna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2331",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/a-coruna/ferrol/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2332",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alava/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2333",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alava/laguardia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2334",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2335",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alicante/alicante/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2336",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alicante/benidorm/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2337",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alicante/calpe/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2338",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/alicante/teulada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2339",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/andalucia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2340",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/aragon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2341",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/badajoz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2342",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/badajoz/merida/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2343",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2344",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2345",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/barcelona/vallromanes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2346",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/burgos/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2347",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/burgos/aranda-de-duero/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2348",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2349",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cadiz/cadiz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2350",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cadiz/tarifa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2351",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2352",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cadiz/zahara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2353",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2354",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2355",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/castilla-y-leon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2356",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2357",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/comunidad-de-madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2358",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2359",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/extremadura/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2360",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/galicia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2361",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/girona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2362",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/girona/lloret-de-mar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2363",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2364",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2365",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2366",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2367",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2368",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2369",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/granada/granada/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2370",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/guadalajara/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2371",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/guadalajara/abanades/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2372",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/guipuzcoa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2373",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/guipuzcoa/orio/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2374",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huelva/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2375",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huelva/isla-cristina/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2376",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huelva/punta-umbria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2377",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2378",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huesca/huesca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2379",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/huesca/monzon/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2380",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2381",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2382",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/lanzarote/teguise/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2383",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/lugo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2384",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/lugo/burela/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2385",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2386",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/madrid/madrid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2387",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2388",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/malaga/estepona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2389",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/malaga/malaga/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2390",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2391",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/mallorca/cala-millor/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2392",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/mallorca/calvia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2393",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2394",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2395",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/murcia/archena/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2396",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/murcia/murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2397",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/pais-vasco/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2398",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2399",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/pontevedra/pontevedra/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2400",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/pontevedra/vigo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2401",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/region-de-murcia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2402",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/salamanca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2403",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/salamanca/bermellar/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2404",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/segovia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2405",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2406",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2407",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/sevilla/sevilla/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2408",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/sevilla/umbrete/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2409",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tarragona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2410",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tarragona/salou/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2411",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tarragona/torroja-del-priorat/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2412",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2413",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2414",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tenerife/arona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2415",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2416",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2417",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2418",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2419",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2420",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/valladolid/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2421",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spa-privado/valladolid/penafiel/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2422",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2423",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2424",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/lanzarote/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2425",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/lanzarote/yaiza/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2426",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2427",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2428",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/spas-para-bebes/tenerife/playa-de-las-americas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2429",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2430",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2431",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/barcelona/barcelona/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2432",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2433",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/castilla-la-mancha/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2434",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/cataluna/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2435",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2436",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2437",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2438",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/islas-baleares/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2439",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/mallorca/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2440",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2441",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2442",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2443",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2444",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/todas/toledo/toledo/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2445",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2446",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2447",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/asturias/parres/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2448",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/canarias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2449",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/comunidad-valenciana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2450",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/distrito-de-evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2451",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/distrito-de-lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2452",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/distrito-de-oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2453",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2454",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/evora/evora/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2455",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/gran-canaria/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2456",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/gran-canaria/maspalomas/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2457",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/gran-canaria/mogan/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2458",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2459",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2460",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/lisboa/ericeira/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2461",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/lisboa/lisboa/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2462",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/oporto/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2463",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/oporto/vila-do-conde/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2464",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/principado-de-asturias/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2465",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/tenerife/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2466",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/tenerife/adeje/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2467",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.name ?? "Promotion landing: 2468",
    path: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.path ?? "/promociones/tratamientos-para-hombre/valencia/valencia/",
    props: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.props ?? false,
    meta: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta || {},
    alias: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.alias || [],
    redirect: Promotion_46pagegZnB7hUSkpsnb7HIlBnt0llljHrVwcTm7hGwlPF2G7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-47e45585-1089-478c-a26e-6c39d145ca72/b2c-build/core/pages/promotion/Promotion.page.vue")
  }
]